<template>
  <div class="ui massive blue borderless main stackable inverted menu d-none d-lg-flex d-xl-flex d-xxl-flex menu--main">
    <div class="ui container">
      <div class="logged left menu">
        <div class="logo item">
          <router-link to="/" class="header logo">
            <img src="../../images/logo.svg" class="ui image" :alt="__('E-Lux')">
          </router-link>
        </div>
        <div class="inverted text">
          {{ __('Hulgi') }}{{
            user ? (user.active_pricelist === 'default' ? __(', Ostuvaade') :
                __(', Müügivaade')) : ''
          }}
        </div>
      </div>
      <div v-if="user" v-cloak class="search item">
        <AppSearch class="desktop" action="/api/search/json" :placeholder="__('Otsi..')"/>
      </div>
      <div class="right menu">
        <div class="settings item" @mouseover="hover2 = true" @mouseleave="hover2 = false">
          <div class="ui top right pointing hover dropdown" :class="{'active visible': hover2}">
            <i class="envelope outline icon"></i>
            {{ __('Abi') }}
            <i class="dropdown m02em icon"></i>
            <div class="menu" :class="{'transition visible': hover2}">
              <a class="phone item" href="tel:003726650073">
                <i class="phone icon"></i>
                {{ __('6650073') }}
              </a>
              <a class="item" href="mailto:hulgi@elux.ee">
                <i class="envelope outline icon"></i>
                {{ __('hulgi@elux.ee') }}
              </a>
              <div class="divider"></div>
              <router-link class="item" to="/uldtingimused">
                <i class="book icon"></i>
                {{ __('Üldtingimused') }}
              </router-link>
              <router-link class="item" to="/hooldus">
                <i class="user cog icon"></i>
                {{ __('Hooldus') }}
              </router-link>
              <router-link class="item" to="/garantii">
                <i class="award icon"></i>
                {{ __('Garantii') }}
              </router-link>
              <router-link class="item" to="/tellimine">
                <i class="address card outline icon"></i>
                {{ __('Tellimine') }}
              </router-link>
              <router-link class="item" to="/kontakt">
                <i class="paper plane outline icon"></i>
                {{ __('Kontakt') }}
              </router-link>
            </div>
          </div>
        </div>
        <template v-if="user">
          <AppCart/>
          <div class="divider-column screen only">
            <div class="ui vertical divider"></div>
          </div>
          <div class="settings item" @mouseover="hover = true" @mouseleave="hover = false">
            <div class="ui top right pointing hover dropdown" :class="{'active visible': hover}">
              <i class="user circle outline icon align-middle"></i>
              <div class="clear left">
                {{ user.name }}
                <div class="company">{{ user.b2b_client ? user.b2b_client.name : '' }}</div>
              </div>
              <i class="cogs icon align-middle"></i>
              <i class="dropdown icon align-middle"></i>
              <div class="menu" :class="{'transition visible': hover}">
                <a
                    v-if="user.active_pricelist === 'sales'"
                    class="item"
                    href="#"
                    @click.prevent="pricelistChange('default')">
                  {{ __('Ostuvaade') }}
                </a>
                <a v-else class="item" href="#" @click.prevent="pricelistChange('sales')">
                  {{ __('Müügivaade') }}
                </a>
                <router-link v-if="user && user.b2b_client && user.b2b_client.can_offers" class="item" to="/pakkumised">
                  {{ __('Pakkumised') }}
                </router-link>
                <router-link class="item" to="/tellimused">{{ __('Tellimused') }}</router-link>
                <router-link class="item" to="/arved">{{ __('Arved') }}</router-link>
                <router-link class="item" to="/seaded">{{ __('Seaded') }}</router-link>
                <a id="logout-btn" class="item" href="#" @click.prevent="doLogout">
                  {{ __('Välju') }}
                  <i class="ui fitted sign out right floated icon"></i>
                </a>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="item">
            <router-link to="/password/reset">{{ __('Unustasid salasõna?') }}</router-link>
          </div>
          <div class="item">
            <router-link to="/login" class="ui red button">{{ __('Sisene') }}</router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {__} from '../utils';
import {mapActions, mapMutations, mapState} from 'vuex';
import AppCart from './AppCart.vue';
import AppSearch from "./AppSearch.vue";

export default {
  components: {
    AppSearch,
    AppCart
  },
  data: () => ({
    hover2: false,
    hover: false
  }),
  methods: {
    __,
    ...mapActions('User', ['pricelistChange', 'logout']),
    ...mapMutations('User', {setUser: 'SET_USER'}),
    doLogout() {
      this.logout().then(() => {
        this.$router.push('/login');
        this.setUser(null);
      }).catch(() => {
      });
    },
  },
  computed: mapState('User', ['user']),
}
</script>

<style scoped>
.logo.item {
  margin-top: 5px;
}

.ui.dropdown > .dropdown.icon.m02em {
  margin-left: 0.2em;
}
</style>