import request from './../request';
import {getField, updateField} from 'vuex-map-fields';

const initialUser = localStorage.getItem('user');

const initialState = () => {
    return {
        user: initialUser && initialUser !== 'undefined' ? JSON.parse(initialUser) : null
    };
};

const getters = {
    getField,
    canOffers: state => state.user && state.user.b2b_client && !!state.user.b2b_client.can_offers,
    isLoggedIn: state => state.user && !!state.user.id,
};

const actions = {
    userLogin({commit}, {form, data}) {
        return request.post('/api/login', data).then(res => {
            commit('SET_USER', res.data.data);
            return res;
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            return Promise.reject(e);
        });
    },
    passwordRequest({}, {email, form}) {
        return request.post('/api/password/email', {email}).then(res => {
            return res;
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            return Promise.reject(e);
        });
    },
    saveSettings({commit, state}, {payload, form}) {
        let key;
        const keys = [
            'offers_en',
            'offer_payment_term',
            'offer_valid_days',
            'offer_price_increase',
            'signature'
        ];
        for (key in state.user) {
            if (state.user[key] && keys.indexOf(key) !== -1) {
                payload.append(key, typeof state.user[key] === "boolean" ? (state.user[key] ? 1 : 0) : state.user[key]);
            }
        }
        payload.append('offers_en', state.user.b2b_client.offers_en ? 1 : 0);
        return request.post('/api/user', payload,
            {headers: {'Content-Type': 'multipart/form-data'}}
        ).then(res => {
            commit('SET_USER', res.data.data);
            return res;
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            return Promise.reject(e);
        });
    },
    passwordReset({commit}, {token, email, password, password_confirmation, form}) {
        return request.post('/api/password/reset', {token, email, password, password_confirmation}).then(res => {
            commit('SET_USER', res.data.data);
            return res;
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            return Promise.reject(e);
        });
    },
    logoRemove({commit}) {
        return request.post('/api/user/logo/remove', {}).then(res => {
            commit('SET_USER', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    async logout({commit}) {
        return await request.post('/api/logout', {}).then(res => {
            commit('SET_USER', null);
            return res;
        });
    },
    pricelistChange({}, type) {
        return request.post('/api/user/pricelist/' + type, {}).then(() => {
            document.location.reload();
        }).catch(() => {
        });
    },
    getUser({commit}) {
        return request.get('/api/user').then(res => {
            commit('SET_USER', res.data.data);
            return res.data.data;
        }).catch(() => {
        });
    },
};

const mutations = {
    updateField,
    SET_USER(state, payload) {
        if (payload === '' || payload === null) {
            localStorage.removeItem('user');
        } else {
            localStorage.setItem('user', JSON.stringify(payload));
        }
        state.user = payload;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};