<template>
  <mounting-portal mount-to=".toast-container" name="toasts" append>
    <div v-for="(toast, i) in toasts" :key="i" class="toast-box compact visible transition">
      <div class="icon success ui toast" @click="rm(i)">
        <i class="checkmark icon"></i>
        <div class="content">
          <div>{{ toast }}</div>
        </div>
      </div>
    </div>
  </mounting-portal>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  computed: mapState('Messages', ['toasts']),
  watch: {
    toasts() {
      if (this.toasts.length > 0) {
        setTimeout(() => {
          this.removeToast();
        }, 2500);
      }
    }
  },
  methods: {
    ...mapMutations('Messages', {removeToast: 'REMOVE_TOAST'}),
    rm(id) {
      this.removeToast(id);
    }
  }
}
</script>

<style scoped>
.toast-box {
  display: table !important;
}

.ui.toast {
  opacity: 1;
}
</style>