import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import {addDays, differenceInDays, format, parseISO, startOfToday, subDays} from "date-fns";
import {et} from "date-fns/locale";

export const copy = object => JSON.parse(JSON.stringify(object));

export const percentageIncrease = row => row.original_price > 0 ? Math.round((row.price - row.original_price) * 100 / row.original_price) : 0;

export const discount = (priceDifference, discount_start, discount_end) => {
    const discountEnd = addDays(parseISO(discount_end ?? ''), 1);
    const discountStart = parseISO(discount_start ?? '');
    const today = startOfToday();
    const startDiff = differenceInDays(today, discountStart);
    const endDiff = differenceInDays(discountEnd, today);
    const hasStarted = discount_start ? startDiff >= 0 : true;
    const hasNotEnded = discount_end ? endDiff >= 0 : false;
    return priceDifference && hasNotEnded && hasStarted ? endDiff : 0;
};

export const isLoadbee = brand => {
    return brand && (brand.toLowerCase() === 'miele' || brand.toLowerCase() === 'bosch');
}

export const isFlixmedia = brand => {
    return brand && (brand.toLowerCase() === 'aeg' || brand.toLowerCase() === 'electrolux');
}

export const formatLocal = (date, formatTo) => format(date, formatTo, {locale: et});

export const isEstorecontent = brand => {
    return brand && (brand.toLowerCase() === 'whirlpool' || brand.toLowerCase() === 'indesit');
}

export const includeRoute = route => {
    const excludeRoutes = [
        'api/offer/',
        'api/search/json',
        'pakkumised/',
        'category/search',
        'properties/search',
        'measures/search',
        'value-ranges/search',
        'price-range/search',
        'selected-filters/search'
    ];
    for (let i = 0; i < excludeRoutes.length; i++) {
        if (route.indexOf(excludeRoutes[i]) !== -1) {
            return false;
        }
    }
    return true;
}

export const oembed = content => {
    const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g;
    return content && content.replace(regex, '<div style="position: relative; height: 0; padding-bottom: 56.2493%;">' +
        '<iframe src="https://www.youtube.com/embed/$1" ' +
        'style="border: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
        'allow="autoplay; encrypted-media" allowfullscreen>' +
        '</iframe>' +
        '</div>');
};

export const imageUrl = (filename, width = false, height = false, changeType = false) => {
    let fallback = '/images/no-image.png';
    if (changeType) {
        const ext = filename ? filename.split('.').pop() : '';
        filename = ext ? filename.replace('.' + ext, '.' + changeType) : '';
        fallback = '/images/no-image.webp';
    }
    height = height ? height : width;
    let dims = '';
    if (width) {
        dims = '/' + width + 'x' + height;
    }
    return import.meta.env.VITE_IMAGES_URL + (filename ? '/storage/' + filename.substring(0, 1) + dims + '/' + filename : fallback);
};

export const salesPriceList = user => user.active_pricelist === 'sales';

export const __ = message => message;

export const logoUrl = filename => {
    return filename ? '/storage/logos/' + filename : '/images/logo_v_bg.png';
};

export const referenceNumber = order_id => {
    const multipliers = [7, 3, 1];
    let total = 0;
    let multiplierKey = 0;
    for (let i = order_id.length - 1; i >= 0; --i) {
        total += parseInt(order_id.substring(i, i + 1)) * multipliers[multiplierKey];
        multiplierKey = multiplierKey < 2 ? ++multiplierKey : 0;
    }
    const closestTen = Math.ceil(total / 10) * 10;
    const checkNum = closestTen - total;
    return order_id.toString() + checkNum;
};

export const OrderStep = {
    CONTACTS: 'checkout-contacts',
    TRANSPORT: 'checkout-transport',
    SERVICES: 'checkout-services',
    PAYMENT: 'checkout-payment',
    CONFIRM: 'checkout-confirm',
}

export const stepComplete = (expected, current) => {
    switch (expected) {
        case OrderStep.CONTACTS:
            return [OrderStep.CONTACTS, OrderStep.TRANSPORT, OrderStep.PAYMENT, OrderStep.CONFIRM].includes(current);
        case OrderStep.TRANSPORT:
            return [OrderStep.TRANSPORT, OrderStep.PAYMENT, OrderStep.CONFIRM].includes(current);
        case OrderStep.PAYMENT:
            return [OrderStep.PAYMENT, OrderStep.CONFIRM].includes(current);
        case OrderStep.CONFIRM:
            return [OrderStep.CONFIRM].includes(current);
    }
}

export const stepDisabled = (expected, current) => {
    switch (expected) {
        case OrderStep.TRANSPORT:
            return !current;
        case OrderStep.PAYMENT:
            return !current || [OrderStep.CONTACTS].includes(current);
        case OrderStep.CONFIRM:
            return !current || [OrderStep.CONTACTS, OrderStep.TRANSPORT].includes(current);
    }
}

export const mimeType = filename => {
    const ext = filename ? filename.split('.').pop().toLowerCase() : '';
    let mime;
    switch (ext) {
        case 'jpg':
        case 'jpeg':
            mime = 'image/jpg';
            break;
        case 'png':
            mime = 'image/png';
            break;
        case 'gif':
            mime = 'image/gif';
            break;
        default:
            mime = '';
    }
    return mime;
};

export const range = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step);

export const orderStatuses = {
    closed: __('Kinnitatud'),
    failed: __('Makse ebaõnnestunud'),
    invoice: __('Maksan hiljem'),
    open: __('Pooleli')
};

export const vat = () => window.vat;

export const vatNice = () => Math.round((vat() - 1) * 100);

export const calcVat = (vat) => Math.round((vat - 1) * 100);

export const offerStatuses = {
    open: __('Ootel'),
    cancelled: __('Tühistatud'),
    confirmed: __('Kinnitatud'),
    archived: __('Arhiveeritud')
};

export const orderStatus = status => orderStatuses[status];

export const isNew = created_at => created_at && differenceInDays(parseISO(created_at ?? ''), subDays(new Date(), 45)) > 0;

export const loan = (amount, months = 48, downPayment = 0) => {
    return Math.ceil((24.22 / 12 / 100 * (amount - downPayment)) / (1 - (1 + 24.22 / 12 / 100) ** -months)) + '€';
};

export const price = value => {
    return isNaN(value) ? value : new Intl.NumberFormat('et-EE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

export const priceFraction = (price, main) => {
    const precision = Math.round(price * 100) !== Math.round(price) * 100 ? 2 : 0;
    const intPart = Math.floor(price);
    const fraction = price - intPart;
    if (main) {
        return intPart;
    } else {
        const frac = Math.round(fraction * 100);
        return (precision === 0 ? '' : (',' + (frac < 10 ? '0' + frac : frac))) + ' €';
    }
};

export const url = (url, params = false) => {
    let query = '';
    const esc = encodeURIComponent;
    if (params) {
        query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }
    return '/' + url + (query ? ('?' + query) : '');
};

export const prodUrl = (urlParams, params = false, prev = false) => {
    let query = '';
    const esc = encodeURIComponent;
    if (params) {
        query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
    }
    return '/' + (prev ? prev + '/' : '') + values(urlParams).join('/') + (query ? ('?' + query) : '');
};

export const paymentMethod = method => {
    const fallback = '-';
    const methods = {
        'ep_citadele': 'Citadele',
        'ep_cc': 'krediitkaart',
        'ep_lhv': 'LHV',
        'ep_luminor': 'Luminor',
        'ep_seb': 'SEB',
        'ep_swed': 'Swedbank',
        'estcard': 'krediitkaart',
        'lhv': 'LHV',
        'invoice': 'arvega',
        'seb': 'SEB',
        'coop': 'Coop Pank',
        'swed': 'Swedbank',
        'test': 'Testpank',
        'sebtest': 'Testpank',
        'esto3': 'ESTO 3',
        'esto': 'ESTO järelmaks',
        'inbank': 'Inbank järelmaks',
        'indivy_slice': 'Indivy SLICE',
        'indivy_plan': 'Indivy PLAN',
    };
    return methods[method] || fallback;
};

export const transportMethods = {
    'TTH3': 'Paigaldamise aadressile',
    'TTH2': 'Tulen ise järgi',
    'TTH1': 'Transport tellija lattu'
};

export const transport = trans => {
    const fallback = '';

    return transportMethods[trans] || fallback;
};

export const isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const paymentTerm = order => {
    let ret = '';
    if (order.payment_method === 'invoice') {
        if (isNumeric(order.payment_term)) {
            ret = ', ' + order.payment_term + 'päeva';
        } else if (order.payment_term === 'E') {
            ret = ', ' + 'ettemaks';
        }
    }
    return ret;
};

export const values = obj => {
    let vals = [];
    if (obj) {
        vals = Object.keys(obj).map(key => {
            return obj[key];
        });
    }
    return vals;
};

export const slash = url => url ? (url.indexOf('/') !== 0 ? '/' + url : url) : '';

export const diff = (object, base) => {
    const changes = (object, base) => transform(object, function (result, value, key) {
        if (!isEqual(value, base[key])) {
            result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
        }
    });
    return changes(object, base);
};

export const mainize = url => import.meta.env.VITE_MAIN_URL + url;