import Vue from 'vue';
import Vuex from 'vuex';
import Loader from './Loader';
import Variables from './Variables';
import Messages from './Messages';
import User from './User';
import Cart from './Cart';
import Order from './Order';
import Page from "./Page";
import News from './News';
import Product from './Product';
import Offer from "./Offer";
import Compare from "./Compare";
import Invoice from "./Invoice";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Loader,
        Messages,
        Variables,
        User,
        Cart,
        Order,
        Page,
        News,
        Product,
        Offer,
        Compare,
        Invoice
    },
    strict: true,
});