<template>
  <div class="prices">
    <template v-if="discount(product.price !== product.price_default, product.discount_start, product.discount_end)">
      <p class="product-card__price product-card__price--old" :class="classes">
        <span class="strike secondary">
          <template v-if="salesPricelist">
            {{
              priceFraction(product.price_default_vat, true)
            }}<small>{{ priceFraction(product.price_default_vat, false) }}</small>
          </template>
          <template v-else>
            {{ priceFraction(product.price_default, true) }}<small>{{
              priceFraction(product.price_default, false)
            }}</small>
          </template>
        </span>
      </p>
      <h3 class="ui header red product-card__price product-card__price--discount">
        <template v-if="salesPricelist">
          {{ priceFraction(product.price_vat, true) }}<small>{{ priceFraction(product.price_vat, false) }}</small>
        </template>
        <template v-else>
          {{ priceFraction(product.price, true) }}<small>{{ priceFraction(product.price, false) }}</small>
          <span class="ui smaller text">{{ __('+KM') }}</span>
        </template>
      </h3>
    </template>
    <h3 v-else class="ui header">
      <template v-if="salesPricelist">
        {{ priceFraction(product.price_vat, true) }}<small>{{ priceFraction(product.price_vat, false) }}</small>
      </template>
      <template v-else>
        {{ priceFraction(product.price, true) }}<small>{{ priceFraction(product.price, false) }}</small>
        <span class="ui smaller text">{{ __('+KM') }}</span>
      </template>
    </h3>
  </div>
</template>

<script>
import {__, discount, priceFraction} from "../utils";

export default {
  name: "ProductPrice",
  props: ['product', 'salesPricelist', 'classes'],
  methods: {
    discount,
    priceFraction,
    __
  }
}
</script>
