import Vue from 'vue';
import PortalVue from 'portal-vue';
import request from './request';
import {extend, localize} from 'vee-validate';
import {digits, email, min, min_value, numeric, regex, required} from 'vee-validate/dist/rules';
import et from 'vee-validate/dist/locale/et.json';
import AppLayout from './AppLayout.vue';
import LazyLoad from "vanilla-lazyload";
import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyLoad from 'vue-lazyload';
import {__} from "./utils";
import router from './routes';
import store from './store/index';
import * as Sentry from '@sentry/vue';
import VueRouterReferer from '@tozd/vue-router-referer';

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration()
        ],
        sampleRate: 0.1,
        tracesSampleRate: 0.01,
        trackComponents: true,
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
    });
}

extend('regex', {
    ...regex,
    message: __('{_field_} ei ole korrektne')
});
extend('min_value', {
    ...min_value,
    message: __('{_field_} ei ole märgitud')
});
extend('digits', {
    ...digits,
    message: __('{_field_} ei ole numbrid')
});
extend('min', {
    ...min,
    message: __('{_field_} peaks olema vähemalt {length} tähemärki')
});
extend('email', email);
extend('password', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: __('{_field_} väljad ei klapi')
});
extend('numeric', numeric);
extend('required', required);
localize('et', et);

Vue.use(VueLazyLoad);
Vue.use(PortalVue);
Vue.use(Vue2TouchEvents);
Vue.use(VueRouterReferer);

Vue.config.productionTip = false;

Vue.prototype.$http = request;

window.sortingOptions = {
    price_asc: __('Hinna järgi kasvavalt'),
    price_desc: __('Hinna järgi kahanevalt'),
    title_asc: __('Pealkirja järgi kasvavalt'),
    title_desc: __('Pealkirja järgi kahanevalt'),
    code_asc: __('Tootekoodi järgi kasvavalt'),
    code_desc: __('Tootekoodi järgi kahanevalt'),
    discount_desc: __('Soodustuse järgi kahanevalt')
};

window.perPage = import.meta.env.VITE_PER_PAGE.split(',');

const lazyLoadOptions = {elements_selector: ".lazy"};
new LazyLoad(lazyLoadOptions);

export default new Vue({
    store,
    router,
    render: h => h(AppLayout)
}).$mount('#vue');
