import request from './../request';

const initialState = () => {
    return {
        newsItem: {},
        news: []
    };
};

const getters = {};

const actions = {
    getNews({commit}) {
        return request.get('/api/news').then(res => {
            commit('SET_NEWS', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    async getNewsItem({commit}, id) {
        const res = await request.get('/api/news/' + id);
        commit('SET_NEWS_ITEM', res.data.data);
        return res.data.data;
    }
};

const mutations = {
    SET_NEWS(state, payload) {
        state.news = payload;
    },
    SET_NEWS_ITEM(state, payload) {
        state.newsItem = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};