<template>
  <div v-on-click-outside="hideSearch" class="ui fluid search">
    <div class="ui left icon right action fluid input" :class="[{'loading': searching},addClass]">
      <i v-if="searchword" class="ui small times link icon" @click="clearSearch"></i>
      <input
id="searchword" v-model="searchword" name="searchword" class="prompt"
             type="text" maxlength="250" :placeholder="placeholder" @click="showResults" @keyup="doSearch">
      <button class="ui icon button" @click="goToSearch">
        <i :class="iconClass" class="search link icon"></i>
      </button>
    </div>
    <div class="results" :class="{'transition visible': searched}" @click.prevent="hideSearch">
      <div v-if="results.length === 0" class="message empty">
        <div class="header">{{ __('Ei leitud ühtegi tulemust.') }}</div>
        <div class="description">{{ __('Võid proovida mõne muu otsisõnaga.') }}</div>
      </div>
      <template v-else-if="callback">
        <div v-for="result in results" :key="result.code" class="result" @click.prevent="selectRow(result)">
          <div class="content">
            <div class="title">{{ result.title }}</div>
            <div class="product-code">{{ result.code }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <router-link v-for="result in results" :key="result.code" class="result" :to="result.url">
          <div class="image">
            <img alt="" style="height:75px;width:75px;" :src="imageUrl(result.filename, 100)">
          </div>
          <div class="content">
            <div class="price">
              <ProductPrice classes="right floated" :product="result"/>
            </div>
            <div class="title">{{ result.title }}</div>
            <div class="product-code">{{ result.code }}</div>
            <div class="description">{{ result.description }}
            </div>
          </div>
        </router-link>
      </template>
      <router-link v-if="rows > 0" :to="'/otsing/' + searchword" class="action">
        {{ __('Kõik tulemused') }}  ({{ rows }})
      </router-link>
    </div>
  </div>
</template>

<script>
import {__, imageUrl} from '../utils';
import {useDebounceFn} from '@vueuse/core';
import ProductPrice from "./ProductPrice.vue";
import {vOnClickOutside} from "@vueuse/components";

export default {
  directives: {
    'onClickOutside': vOnClickOutside,
  },
  components: {ProductPrice},
  props: {
    action: String,
    callback: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    addClass: String,
    iconClass: String,
    select: Function,
    noBtnAction: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    source: null,
    searched: false,
    rows: 0,
    searching: false,
    searchword: '',
    results: []
  }),
  created() {
    this.debouncedSearch = useDebounceFn(this.search, 500)
  },
  methods: {
    __,
    imageUrl,
    doSearch(e) {
      if (e.target.value.length >= 3) {
        if (e.keyCode === 13) {
          this.goToSearch();
        } else {
          this.debouncedSearch();
        }
      }
    },
    goToSearch() {
      if (this.noBtnAction) {
        this.debouncedSearch();
      } else {
        this.source && this.source.cancel('Operation cancelled by the user.');
        this.$router.push({
          name: 'search',
          params: {searchPhrase: this.searchword}
        }).catch(() => {
        });
      }
    },
    clearSearch() {
      this.hideSearch();
      this.results = [];
      this.searchword = '';
    },
    hideSearch() {
      this.source && this.source.cancel('Operation canceled by the user.');
      this.searched = false;
    },
    selectRow(row) {
      this.$emit('select', row);
    },
    showResults() {
      if (this.results && this.results.length > 0 && !this.searched) {
        this.searched = true;
      }
    },
    search() {
      this.searching = true;
      this.$http.get(this.action, {params: {query: this.searchword}}).then(res => {
        this.searching = false;
        this.results = res.data.results;
        this.rows = res.data.rows;
        this.searched = true;
      }).catch(() => {
      });
    }
  }
}
</script>
<style scoped>
.fluid.search > .small.icon {
  cursor: pointer;
}

div.desktop #searchword {
  border: 0;
}
</style>
