import axios from 'axios';
import store from './store/index';
import router from './routes';
import {includeRoute} from "./utils";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;

axios.interceptors.request.use(config => {
    includeRoute(config.url) && store.commit('Loader/START_LOADING');
    store.commit('Messages/CLEAR_MESSAGES');
    return config;
}, e => {
    includeRoute(e.config.url) && store.commit('Loader/FINISH_LOADING');
    e.response.data.errors && store.commit('Messages/SET_ERRORS', e.response.data.errors);
    e.response.data.message && store.commit('Messages/SET_ERROR', e.response.data.message);
    e.response.data.warning && store.commit('Messages/SET_WARNING', e.response.data.warning);
    return Promise.reject(e);
});

axios.interceptors.response.use(res => {
    includeRoute(res.config.url) && store.commit('Loader/FINISH_LOADING');
    store.commit('Messages/CLEAR_MESSAGES');
    res.data.message && store.commit('Messages/ADD_TOAST', res.data.message);
    res.data.warning && store.commit('Messages/SET_WARNING', res.data.warning);
    return res;
}, e => {
    includeRoute(e.config.url) && store.commit('Loader/FINISH_LOADING');
    e.response.data && e.response.data.errors && store.commit('Messages/SET_ERRORS', e.response.data.errors);
    e.response.data && e.response.data.message && '/api/order' !== e.config.url && store.commit('Messages/SET_ERROR', e.response.data.message);
    if (e.response.status === 401) {
        store.commit('User/SET_USER', null);
        if (['contact', 'page', 'login', '404', 'password-reset', 'password-request'].indexOf(router.currentRoute.name) === -1) {
            router.push('/login').then(() => {
            });
        }
    } else if (e.response.status === 403 && e.response.data.redirect) {
        router.push(e.response.data.redirect).catch(() => {
        });
    }
    return Promise.reject(e);
});

export default axios;