const initialState = () => {
    return {
        errs: {},
        toasts: [],
        error: window.localErrors || '',
        warning: null
    };
};

const getters = {};
const actions = {};

const mutations = {
    ADD_TOAST(state, toast) {
        state.toasts = [
            ...state.toasts,
            toast
        ];
    },
    REMOVE_TOAST(state, position = 0) {
        state.toasts.splice(position, 1);
    },
    CLEAR_MESSAGES(state, payload) {
        state.errs = {};
        if (payload && payload.manual) {
            state.error = null;
            state.warning = null;
        }
    },
    SET_ERROR(state, payload) {
        state.error = payload;
    },
    SET_WARNING(state, payload) {
        state.warning = payload;
    },
    SET_ERRORS(state, payload) {
        state.errs = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};