<template>
  <div v-if="loading > 0" class="ui active inverted dimmer">
    <div class="ui large bold red elastic text primary loader">{{ __('Laen lehte...') }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {__} from '../utils';

export default {
  props: ['element'],
  methods: {
    __
  },
  computed: {
    ...mapState('Loader', ['loading'])
  }
}
</script>