<template>
  <picture v-element-visibility="visibilityChanged">
    <source
v-if="isExtensionSupported('avif')"
            type="image/avif"
            v-bind="{ [!isReadyForRender ? 'data-srcset' : 'srcset']: imageUrl(image, width, height, 'avif')}">
    <source
v-if="isExtensionSupported('webp')"
            type="image/webp"
            v-bind="{ [!isReadyForRender ? 'data-srcset' : 'srcset']: imageUrl(image, width, height, 'webp')}">
    <source
v-if="isExtensionSupported('jpg')"
            type="image/jpg"
            v-bind="{ [!isReadyForRender ? 'data-srcset' : 'srcset']: imageUrl(image, width, height, 'jpg')}">
    <source
v-if="isExtensionSupported('png')"
            type="image/png"
            v-bind="{ [!isReadyForRender ? 'data-srcset' : 'srcset']: imageUrl(image, width, height, 'png')}">
    <img
:alt="alt"
         :width="width"
         :height="height"
         :loading="lazy ? 'lazy': 'eager'"
         :data-src="imageUrl(image, width)"
         :src="isReadyForRender ? imageUrl(image, width) : imageUrl(undefined, width, height)"
    >
  </picture>
</template>

<script>
import {imageUrl} from "../utils";
import {vElementVisibility} from '@vueuse/components'

export default {
  directives: {
    'elementVisibility': vElementVisibility
  },
  props: {
    extensions: String,
    pictureClassName: String,
    imgClassName: String,
    image: String,
    alt: String,
    width: String,
    height: String,
    lazy: Boolean
  },
  data: () => ({
    visible: false
  }),
  computed: {
    isReadyForRender() {
      return !this.lazy || 'loading' in HTMLImageElement.prototype || this.visible;
    }
  },
  methods: {
    imageUrl,
    isExtensionSupported(ext) {
      if (!this.extensions) {
        return false;
      }
      return this.extensions.split(",").includes(ext);
    },
    visibilityChanged(visible) {
      if (visible) {
        this.visible = true;
      }
    }
  }
}
</script>
