import request from './../request';

const initialState = () => {
    return {
        tree: [],
        brands: [],
        payment_methods: [],
        counties: []
    };
};

const getters = {};

const actions = {
    getVariables({commit}, types) {
        return request.get('/api/variables/' + types.join(',')).then(res => {
            types.forEach(type => {
                commit('SET_VARIABLE', {
                    type,
                    data: res.data[type]
                });
            });
            return res;
        }).catch(() => {
        });
    }
};

const mutations = {
    SET_VARIABLE(state, payload) {
        state[payload.type] = payload.data;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};