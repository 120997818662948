<template>
  <div class="box pushable" :style="{ overflow: mobile ? 'hidden' : 'auto' }">
    <MenuMobile v-if="isMobile"/>
    <div id="pusher" class="pusher">
      <AppToast/>
      <HeaderMobile v-if="isMobile"/>
      <HeaderDesktop v-else/>
      <MenuDesktop v-if="user && isDesktop"/>
      <div class="ui main container">
        <div class="sixteen wide column mb-sm hide-print">
          <img src="../images/logo_v_bg.png" class="ui image logo" alt="E-Lux">
        </div>
        <AppMessages/>
        <AppLoader/>
        <router-view/>
      </div>
      <CartModal/>
      <AppFooter/>
    </div>
    <div v-if="mobile" v-touch:swipe.left="hideMenu" class="mobile-menu-overlay" @click="hideMenu()"></div>
  </div>
</template>

<script>
import {breakpointsTailwind, useBreakpoints} from '@vueuse/core';
import {mapActions, mapMutations, mapState} from 'vuex';
import CartModal from "./components/CartModal.vue";
import AppMessages from './components/AppMessages.vue';
import AppLoader from './components/AppLoader.vue';
import MenuMobile from "./components/MenuMobile.vue";
import AppFooter from "./components/AppFooter.vue";
import HeaderMobile from "./components/HeaderMobile.vue";
import HeaderDesktop from "./components/HeaderDesktop.vue";
import MenuDesktop from "./components/MenuDesktop.vue";
import AppToast from './components/AppToast.vue';

export default {
  components: {
    AppToast,
    MenuDesktop,
    HeaderDesktop,
    HeaderMobile,
    AppFooter,
    CartModal,
    AppMessages,
    AppLoader,
    MenuMobile
  },
  methods: {
    hideMenu() {
      if (this.mobile) {
        this.toggleMenu();
      }
    },
    ...mapActions('User', ['getUser']),
    ...mapActions('Page', ['getMenu']),
    ...mapMutations('Loader', {toggleMenu: 'TOGGLE_MENU'}),
  },
  computed: {
    ...mapState('Loader', ['mobile']),
    ...mapState('User', ['user']),
  },
  watch: {
    breakpoints: {
      handler(newValue) {
        this.isMobile = newValue.isSmallerOrEqual('md');
        this.isDesktop = newValue.isGreater('md');
      },
      deep: true
    }
  },
  data: () => ({
    breakpoints: null,
    isMobile: false,
    isDesktop: true,
  }),
  created() {
    this.breakpoints = useBreakpoints(breakpointsTailwind);
    this.getMenu();
    setTimeout(() => {
      this.getUser();
    }, 1000);
  }
}
</script>

<style>
.box {
  position: relative;
  min-height: 100vh;
}

.pusher {
  padding-bottom: 23rem;
}

.mobile-menu-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
}
</style>