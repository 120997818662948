import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

const ProductCategory = () => import('./pages/ProductCategory.vue');
const ShoppingCart = () => import('./pages/ShoppingCart.vue');
const ClientOffer = () => import('./pages/ClientOffer.vue');
const ClientOffers = () => import('./pages/ClientOffers.vue');
const ProductsCompare = () => import('./pages/ProductsCompare.vue');
const ClientOrder = () => import('./pages/ClientOrder.vue');
const ClientOrders = () => import('./pages/ClientOrders.vue');
const CartCheckout = () => import('./pages/CartCheckout.vue');
const CheckoutTransport = () => import('./pages/CheckoutTransport.vue');
const CheckoutPayment = () => import('./pages/CheckoutPayment.vue');
const CheckoutContacts = () => import('./pages/CheckoutContacts.vue');
const CheckoutConfirm = () => import('./pages/CheckoutConfirm.vue');
const AppContact = () => import('./pages/AppContact.vue');
const AppPage = () => import('./pages/AppPage.vue');
const AppHome = () => import('./pages/AppHome.vue');
const NewsItem = () => import("./pages/NewsItem.vue");
const ClientSettings = () => import('./pages/ClientSettings.vue');
const ClientInvoices = () => import("./pages/ClientInvoices.vue");
const ClientInvoice = () => import("./pages/ClientInvoice.vue");
const PaymentSuccess = () => import("./pages/PaymentSuccess.vue");
const ProductPage = () => import("./pages/ProductPage.vue");
const FourOFour = () => import("./pages/FourOFour.vue");
const UserLogin = () => import("./pages/UserLogin.vue");
const PasswordReset = () => import("./pages/PasswordReset.vue");
const PasswordRequest = () => import("./pages/PasswordRequest.vue");

const canOffers = (to, from, next) => {
    if (store.getters['User/isLoggedIn'] === true) {
        if (store.getters['User/canOffers'] === false) {
            next('/');
        } else {
            next();
        }
    } else {
        next('/login');
    }
};

const loggedIn = (to, from, next) => {
    if (store.getters['User/isLoggedIn'] === true) {
        next();
    } else {
        next('/login');
    }
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: AppHome,
        beforeEnter: loggedIn
    },
    {
        path: '/login',
        name: 'login',
        component: UserLogin
    },
    {
        path: '/password/reset/:token',
        name: 'password-reset',
        component: PasswordReset
    },
    {
        path: '/password/reset',
        name: 'password-request',
        component: PasswordRequest
    },
    {
        path: '/seaded',
        name: 'settings',
        component: ClientSettings,
        beforeEnter: loggedIn
    },
    {
        path: '/arved',
        name: 'invoices',
        component: ClientInvoices,
        beforeEnter: loggedIn
    },
    {
        path: '/arved/:id(\\d+)',
        name: 'invoice',
        component: ClientInvoice,
        beforeEnter: loggedIn
    },
    {
        path: '/ostukorv',
        name: 'cart',
        component: ShoppingCart,
        beforeEnter: loggedIn
    },
    {
        path: '/uudised/:url',
        name: 'news',
        component: NewsItem,
        beforeEnter: loggedIn
    },
    {
        path: '/ostukorv',
        name: 'checkout',
        component: CartCheckout,
        beforeEnter: loggedIn,
        children: [
            {
                path: 'kontakt',
                name: 'checkout-contacts',
                component: CheckoutContacts
            },
            {
                path: 'transport',
                name: 'checkout-transport',
                component: CheckoutTransport
            },
            {
                path: 'makse',
                name: 'checkout-payment',
                component: CheckoutPayment
            },
            {
                path: 'kinnita',
                name: 'checkout-confirm',
                component: CheckoutConfirm
            }
        ]
    },
    {
        path: '/(' + window.pages + ')',
        name: 'page',
        component: AppPage
    },
    {
        path: '/kontakt',
        name: 'contact',
        component: AppContact
    },
    {
        path: '/otsing/:searchPhrase?/:filters?',
        name: 'search',
        component: ProductCategory,
        meta: {search: true},
        beforeEnter: loggedIn
    },
    {
        path: '/vordlus',
        name: 'compare',
        component: ProductsCompare,
        beforeEnter: loggedIn
    },
    {
        name: 'offersRedirect',
        path: '/pakkumised',
        redirect: '/pakkumised/open'
    },
    {
        path: '/tellimused',
        name: 'orders',
        component: ClientOrders,
        beforeEnter: loggedIn
    },
    {
        path: '/tellimused/:id(\\d+)',
        name: 'order',
        component: ClientOrder,
        beforeEnter: loggedIn
    },
    {
        path: '/pakkumised/:id(\\d+)',
        name: 'offer',
        component: ClientOffer,
        beforeEnter: canOffers
    },
    {
        path: '/pakkumised/:status?',
        name: 'offers',
        component: ClientOffers,
        beforeEnter: canOffers
    },
    {
        path: '/makse-edukas/:id(\\d+)',
        name: 'payment-success',
        component: PaymentSuccess,
        beforeEnter: loggedIn
    },
    {
        path: '/(' + window.categories + ')/:filters?',
        name: 'category',
        component: ProductCategory,
        meta: {search: false},
        beforeEnter: loggedIn
    },
    {
        path: '/:slug',
        name: 'product',
        component: ProductPage,
        beforeEnter: loggedIn
    },
    {
        path: '*',
        name: '404',
        component: FourOFour
    }
];

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
});
router.beforeEach((to, from, next) => {
    if (from.name !== null && to.name !== from.next && from.name !== 'login') {
        store.commit('Messages/CLEAR_MESSAGES', {manual: true});
    }
    setTimeout(() => {
        document.getElementById('pusher').scrollIntoView();
    }, 500);
    Object.defineProperty(
        document,
        'referrer',
        {
            configurable: true,
            get: () => {
                return from.name
                    ? window.location.origin + from.path
                    : ''
            },
        }
    );
    next();
});
export default router;