<template>
  <mounting-portal mount-to="#portals" name="cart-modal" append>
    <Modal v-if="showModal" v-model="showModal" :close-icon="true">
      <ModalHeader>{{ __('Toode on lisatud ostukorvi!') }}</ModalHeader>
      <ModalContent scrolling image>
        <ModalDescription>
          <div class="ui stackable grid">
            <div class="four wide column center aligned">
              <router-link class="ui image product-url" :to="url(product.url)">
                <ProductPicture :image="product.filename" :title="product.title" size="100"/>
              </router-link>
            </div>
            <div class="eight wide column">
              <router-link class="product-link" :to="url(product.url)">
                <h3>{{ product.title }}</h3>
              </router-link>
              <h5>{{ product.code }}</h5>
              <ProductPrice
:product="product"
                            :sales-pricelist="salesPriceList(user)"/>
            </div>
            <div class="four wide column">
              <div class="ui divider mobile only"></div>
              <h2 class="ui header">{{ __('Ostukorvi sisu') }}</h2>
              <p>{{ __('Tooteid:') }} <strong>{{ cart.items }}</strong>
              </p>
              <p>
                {{ __('Summa:') }} <strong>
                {{ price((salesPriceList(user) ? cart.total : cart.total_vat)) }}</strong>
              </p>
            </div>
          </div>
        </ModalDescription>
      </ModalContent>
      <ModalActions>
        <button class="ui left floated basic button" @click="toggleModal(false)">
          <i class="left chevron icon"></i>
          <span>{{ __('Lisa veel tooteid') }}</span>
        </button>
        <button class="ui negative button" @click="goToCart">
          {{ __('Vormista tellimus') }}
          <i class="in cart icon"></i>
        </button>
      </ModalActions>
    </Modal>
  </mounting-portal>
</template>

<script>
import Modal from "semantic-ui-vue/dist/umd/modules/modal/sui-modal.min";
import ModalContent from "semantic-ui-vue/dist/umd/modules/modal/sui-modal-content.min";
import ModalDescription from "semantic-ui-vue/dist/umd/modules/modal/sui-modal-description.min";
import ModalHeader from "semantic-ui-vue/dist/umd/modules/modal/sui-modal-header.min";
import ModalActions from "semantic-ui-vue/dist/umd/modules/modal/sui-modal-actions.min";
import {__, price, salesPriceList, url} from "../utils";
import {mapMutations, mapState} from 'vuex';
import ProductPicture from "./ProductPicture.vue";
import ProductPrice from "./ProductPrice.vue";

export default {
  name: "CartModal",
  components: {
    ProductPrice,
    ProductPicture,
    Modal,
    ModalContent,
    ModalDescription,
    ModalHeader,
    ModalActions
  },
  methods: {
    url,
    __,
    price,
    salesPriceList,
    ...mapMutations('Cart', {toggleModal: 'TOGGLE_MODAL'}),
    goToCart() {
      this.toggleModal(false);
      this.$router.push({name: 'cart'});
    }
  },
  computed: {
    showModal: {
      get() {
        return this.modal;
      },
      set() {
        this.toggleModal(false);
      }
    },
    ...mapState('Cart', ['cart', 'product', 'modal']),
    ...mapState('User', ['user'])
  }
}
</script>

<style>
@media only screen and (max-width: 768px) {
  .ui.modal > .close {
    top: .4rem !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 430px) {
  .ui.left.floated.basic.button > span {
    display: none;
  }
}

.ui.modal > .content > .description {
  flex: 0 1 auto;
}
</style>