import request from './../request';
import {orderStatuses} from "../utils";
import {getField, updateField} from 'vuex-map-fields';

const initialState = () => {
    return {
        order: {},
        meta: {},
        orders: [],
        statuses: orderStatuses
    };
};

const getters = {
    getField
};

const actions = {
    getOrders({commit}, params) {
        return request.get('/api/orders', {params}).then(res => {
            commit('SET_ORDERS', res.data.data);
            commit('SET_META', res.data.meta);
            return res;
        }).catch(() => {
        });
    },
    async continueOrder({}, id) {
        return await request.post('/api/orders/continue/' + id);
    },
    saveOrder({commit}, {order, form}) {
        return request.post('/api/order', order).then(res => {
            commit('SET_ORDER', res.data.data);
            return res;
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            return Promise.reject(e);
        });
    },
    async getOrder({commit}, id) {
        const res = await request.get('/api/orders/' + id);
        commit('SET_ORDER', res.data.data);
        return res.data.data;
    },
    getCurrentOrder({commit}, createNew = false) {
        return request.get('/api/order' + (createNew ? '/new' : '')).then(res => {
            commit('SET_ORDER', res.data.data);
            return res.data.data;
        }).catch(() => {
        });
    },
};

const mutations = {
    updateField,
    SET_ORDER(state, payload) {
        state.order = payload;
    },
    SET_META(state, payload) {
        state.meta = payload;
    },
    SET_ORDERS(state, payload) {
        state.orders = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};