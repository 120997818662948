import request from './../request';

const initialState = () => {
    return {
        compares: []
    };
};

const getters = {};

const actions = {
    getCompares({commit}, detailed = false) {
        return request.get('/api/compares', {params: {detailed}}).then(res => {
            commit('SET_COMPARES', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    addToCompare({commit}, id) {
        return request.post('/api/compare/add', {id}).then(res => {
            commit('SET_COMPARES', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    compareRemove({commit}, {id, detailed = false}) {
        return request.post('/api/compare/remove', {id, detailed}).then(res => {
            commit('SET_COMPARES', res.data.data);
            return res;
        }).catch(() => {
        });
    }
};

const mutations = {
    SET_COMPARES(state, payload) {
        state.compares = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};