const state = () => {
    return {
        loading: 0,
        mobile: false,
    };
};

const getters = {
    loading: state => state.loading,
};

const mutations = {
    TOGGLE_MENU(state) {
        state.mobile = !state.mobile;
    },
    START_LOADING: state => state.loading++,
    FINISH_LOADING: state => state.loading--,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
