<template>
  <div class="row" :class="rowClass">
    <div class="ui accordion column">
      <div class="title" :class="[{'active': tgl}, titleClass]" @click="toggle">
        {{ title }}
        <i class="dropdown icon"></i>
      </div>
      <div class="content" :class="{'active': tgl}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rowClass: {
      type: [String],
      default: ''
    },
    title: {
      type: [String],
      require: true
    },
    toggled: {
      type: [Boolean],
      default: false
    },
    titleClass: {
      type: [String]
    }
  },
  data: () => ({
    tgl: true
  }),
  watch: {
    toggled: {
      handler() {
        this.tgl = this.toggled;
      },
      immediate: true
    }
  },
  methods: {
    toggle() {
      this.tgl = !this.tgl;
    }
  }
}
</script>