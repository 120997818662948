<template>
  <div>
    <div class="ui massive blue inverted fluid main menu d-none d-sm-flex d-md-flex">
      <div class="toggle item" @click="toggleMenu">
        <i class="content large icon"></i>
      </div>
      <div v-if="user" class="logged left menu">
        <router-link to="/" class="header logo item">
          <img src="../../images/logo.svg" class="ui image" :alt="__('E-Lux')">
        </router-link>
        <div class="logged item">
          <router-link to="/seaded" class="tiny font inverted">
            <strong>{{ user.b2b_client.name }}</strong>, <em>{{ user.name }}</em>
          </router-link>
        </div>
      </div>
      <router-link v-else to="/" class="header center right logo item">
        <img src="../../images/logo.svg" class="ui image" :alt="__('E-Lux')">
      </router-link>
      <template v-if="user">
        <a href="#" class="right search item" @click.prevent="toggleSearch">
          <i class="search icon"></i>
        </a>
        <AppCart/>
      </template>
      <template v-else>
        <a href="tel:003726650073" class="right item">
          <i class="phone icon"></i>
        </a>
        <div class="item">
          <router-link to="/login" class="ui red icon button login">
            <i class="sign in icon"></i>
          </router-link>
        </div>
      </template>
    </div>
    <div v-if="user && showSearch" class="ui red main one item inverted menu">
      <div class="search item">
        <AppSearch
add-class="large inverted" icon-class="inverted" class="mobile" action="/api/search/json"
                :placeholder="__('Otsi..')"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {__} from "../utils";
import AppSearch from "./AppSearch.vue";
import AppCart from './AppCart.vue';

export default {
  name: "HeaderMobile",
  components: {
    AppSearch,
    AppCart
  },
  data: () => ({
    showSearch: false
  }),
  computed: mapState('User', ['user']),
  methods: {
    __,
    ...mapMutations('Loader', {toggleMenu: 'TOGGLE_MENU'}),
    toggleSearch() {
      this.showSearch = !this.showSearch;
    }
  }
}
</script>

<style>
.ui.inverted.menu div.toggle.item {
  padding-right: 0;
}

a.tiny.font.inverted {
  font-size: 0.7em;
}
</style>