<template>
  <AppImage
v-if="image"
             :image="image"
             :picture-class-name="pictureClassName"
             :image-class-name="className"
             :width="size"
             :height="size"
             :alt="title"
             :lazy="lazy"
             extensions="webp,jpg"
  />
  <NoImage v-else :class-name="className"/>
</template>

<script>
import AppImage from './AppImage.vue'
import NoImage from './NoImage.vue';
import {imageUrl, mimeType} from "../utils";

export default {
  components: {
    NoImage,
    AppImage,
  },
  props: {
    image: String,
    title: String,
    size: String,
    className: String,
    styles: String,
    pictureClassName: String,
    lazy: Boolean
  },
  methods: {
    mimeType,
    imageUrl,
  }
}
</script>
