<template>
  <div class="ui large main borderless menu red inverted menu--secondary d-none d-lg-flex d-xl-flex d-xxl-flex">
    <div class="ui container">
      <router-link class="item" to="/eripakkumised">{{ __('Eripakkumised') }}</router-link>
      <menu-item v-for="(item, key) in menu" :key="key" :item="item"/>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {__} from '../utils';
import MenuItem from "./MenuItem.vue";

export default {
  name: "MenuDesktop",
  components: {MenuItem},
  methods: {
    __,
  },
  computed: {
    ...mapState('Page', ['menu'])
  }
}
</script>

<style scoped>
.ui.container {
  overflow: hidden;
}
</style>
