import request from './../request';
import {getField, updateField} from 'vuex-map-fields';

const initialState = () => {
    return {
        offer: {},
        rows: [],
        offers: [],
        counts: [],
        meta: {}
    };
};

const getters = {
    getField
};

const actions = {
    getOffersData({commit}) {
        return request.get('/api/offers/index').then(res => {
            commit('SET_COUNTS', res.data.counts);
            return res;
        }).catch(() => {
        });
    },
    async previewOffer({}, {id, params = {}}) {
        return await request.get('/api/offers/preview/' + id, {params});
    },
    async archiveOffer({}, id) {
        return await request.post('/api/offers/archive', {id});
    },
    async createOffer({commit}) {
        const res = await request.post('/api/offers/add', {});
        commit('SET_OFFER', res.data.data);
        commit('SET_OFFER_ROWS', res.data.data.rows);
        return res.data.data;
    },
    async copyOffer({}, id) {
        return await request.post('/api/offers/copy', {id});
    },
    async orderOffer({commit}, id) {
        request.post('/api/offers/order', {id}).then(res => {
            commit('Cart/SET_CART', res.data, {root: true});
        }).catch(e => {
            return Promise.reject(e);
        })
    },
    async removeOffer({}, id) {
        return await request.post('/api/offers/delete', {id});
    },
    getOffers({commit}, {status, params}) {
        return request.get('/api/offers/' + status, {params}).then(res => {
            commit('SET_OFFERS', res.data.data);
            commit('SET_META', res.data.meta);
            return res;
        }).catch(() => {
        });
    },
    removeRow({commit}, {rowId, id}) {
        return request.post('/api/offers/row/remove', {id, rowId}).then(res => {
            commit('SET_OFFER_ROWS', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    addOfferRow({commit}, {product_id, own_product, id}) {
        return request.post('/api/offers/row/add', {id, product_id, own_product}).then(res => {
            commit('SET_OFFER_ROWS', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    async getOffer({commit}, id) {
        const res = await request.get('/api/offer/' + id);
        commit('SET_OFFER', res.data.data);
        commit('SET_OFFER_ROWS', res.data.data.rows);
        return res.data.data;
    },
    saveOffer({commit, state}, {id}) {
        return request.post('/api/offer/' + id, {...state.offer}).then(res => {
            commit('SET_OFFER', res.data.data);
            commit('SET_OFFER_ROWS', res.data.data.rows);
            return res;
        }).catch(e => {
            return Promise.reject(e);
        });
    }
};

const mutations = {
    updateField,
    SET_OFFERS(state, payload) {
        state.offers = payload;
    },
    SET_OFFER(state, payload) {
        state.offer = payload;
    },
    SET_OFFER_ROWS(state, payload) {
        state.offer.rows = payload;
        state.rows = payload;
    },
    SET_META(state, payload) {
        state.meta = payload;
    },
    SET_COUNTS(state, payload) {
        state.counts = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};