import request from './../request';
import {getField, updateField} from 'vuex-map-fields';

const initialState = () => {
    return {
        transports: [],
        cart: {},
        product: {},
        modal: false
    };
};

const getters = {
    getField,
    cartItems: state => (state.cart.items || 0)
};

const actions = {
    clearCart({commit}) {
        return request.post('/api/cart/clear', {}).then(res => {
            commit('SET_CART', res.data);
            return res;
        }).catch(() => {
        });
    },
    setTransport({commit}, {code, county = ''}) {
        return request.post('/api/cart/transport', {code, county}).then(res => {
            commit('SET_CART', res.data);
            return res;
        }).catch(() => {
        });
    },
    removeProduct({commit}, id) {
        return request.post('/api/cart/delete', {id}).then(res => {
            commit('SET_CART', res.data);
            return res;
        }).catch(() => {
        });
    },
    changeCart({commit}, {id, quantity}) {
        return request.post('/api/cart/update', {id, quantity}).then(res => {
            commit('SET_CART', res.data);
            return res;
        }).catch(() => {
        });
    },
    getTransports({commit}) {
        return request.get('/api/cart/transports').then(res => {
            commit('SET_TRANSPORTS', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    addToCart({commit}, {id, quantity = 1, row_id = null, checked = false, toggleModal = true}) {
        return request.post('/api/cart/insert', {id, row_id, quantity, checked}).then(res => {
            commit('SET_CART', res.data.cart);
            commit('SET_PRODUCT', res.data.product);
            toggleModal && commit('TOGGLE_MODAL', true);
            return res;
        }).catch(() => {
        });
    },
    getCart({commit}) {
        return request.get('/api/cart').then(res => {
            commit('SET_CART', res.data);
            return res;
        }).catch(() => {
        });
    },
};

const mutations = {
    updateField,
    SET_TRANSPORTS(state, payload) {
        state.transports = payload;
    },
    SET_CART(state, payload) {
        state.cart = payload;
    },
    SET_PRODUCT(state, payload) {
        state.product = payload;
    },
    TOGGLE_MODAL(state, payload) {
        state.modal = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};