import request from './../request';

const initialState = () => {
    return {
        page: {},
        footer: {},
        menu: []
    };
};

const getters = {};

const actions = {
    getMenu({commit}) {
        return request.get('/api/categories/menu').then(res => {
            commit('SET_MENU', res.data);
            return res;
        }).catch(() => {
        });
    },
    getFooter({commit}) {
        return request.get('/api/pages/hook/footer-b2b').then(res => {
            commit('SET_FOOTER', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    async getBySlug({commit}, slug) {
        const res = await request.get('/api/pages/slug/' + slug);
        commit('SET_PAGE', res.data.data);
        return res;
    },
    getByHook({commit}, hook) {
        return request.get('/api/pages/hook/' + hook).then(res => {
            commit('SET_PAGE', res.data.data);
            return res;
        }).catch(() => {
        });
    },
    sendFeedback({}, {feedback, form}) {
        return request.post('/api/feedback', feedback).then(() => {
        }).catch(e => {
            e.response.data.errors && form.setErrors(e.response.data.errors);
            throw e;
        });
    }
};

const mutations = {
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_FOOTER(state, payload) {
        state.footer = payload;
    },
    SET_MENU(state, payload) {
        state.menu = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};