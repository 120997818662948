<template>
  <router-link to="/ostukorv" class="item cart">
    <i class="in cart icon"></i>
    <span class="text">{{ __('Ostukorv') }}</span>
    <span class="cnt">({{ cartItems }})</span>
  </router-link>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {__} from '../utils';

export default {
  methods: {
    __,
    ...mapActions('Cart', ['getCart']),
  },
  computed: {
    ...mapGetters('Cart', ['cartItems'])
  },
  created() {
    this.getCart();
  }
}
</script>

<style scoped>
span.text {
  margin-right: 0.3rem;
}

@media only screen and (max-width: 768px) {
  span.text, span.cnt {
    display: none;
  }
}
</style>
