<template>
  <div>
    <div
        ref="link"
        class="main item"
        :class="'c-' + item.id"
        @mouseenter="showMenu"
        @mouseleave="hideMenu">
      {{ item.title ? item.title : '' }}
      <i class="ui dropdown icon !ml-2 !mr-1 !w-2"></i>
    </div>
    <div
        ref="elem"
        class="ui basic bottom attached popup bottom center"
        :style="style"
        :class="{'visible transition': visible > 0, 'double': item.two_columns}"
        @mouseenter="showMenu"
        @mouseleave="hideMenu">
      <div class="ui relaxed grid">
        <div class="row">
          <template v-if="item.children.length > 0">
            <template v-if="item.two_columns">
              <div
                  v-for="(part, key) in item.children"
                  :key="key"
                  class="eight wide column">
                <div v-for="(item2, key2) in part" :key="key2" class="ui link list">
                  <router-link
class="ui item hover-products"
                               :class="'c-' + item2.id" :to="url(item2.url)">
                    <h4 class=" ui header">{{ item2.title }}</h4>
                  </router-link>
                  <router-link
v-for="(link, name) in item2.links"
                               :key="link" class="item" :to="link">
                    {{ name }}
                  </router-link>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="sixteen wide column">
                <div v-for="(item2, key2) in item.children" :key="key2" class="ui link list">
                  <router-link
class="ui item hover-products" :class="'c-' + item2.id"
                               :to="url(item2.url)">
                    <h4 class=" ui header">{{ item2.title }}</h4>
                  </router-link>
                  <router-link
v-for="(link, name) in item2.links"
                               :key="link" class="item" :to="link">
                    {{ name }}
                  </router-link>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {url} from "../utils";

export default {
  name: "MenuItem",
  props: ['item'],
  data: () => ({
    visible: 0,
    style: '',
  }),
  mounted() {
    this.move(this.$refs.link);
  },
  created() {
    window.addEventListener('resize', () => {
      this.move(this.$refs.link);
    });
  },
  methods: {
    url,
    hideMenu() {
      this.visible--;
    },
    move(e) {
      setTimeout(() => {
        const elem = this.$refs.elem;
        if (elem && e) {
          const parentBounds = e.getBoundingClientRect();
          const bounds = elem.getBoundingClientRect();
          const position = bounds.left - parentBounds.left;
          if (position > 0) {
            this.style = 'left: ' + e.offsetLeft + 'px';
          }
        }
      }, 10);
    },
    showMenu() {
      this.visible++;
      this.move(this.$refs.link);
    }
  }
}
</script>

<style>
div.main.item {
  cursor: pointer;
  height: 100%;
}

.borderless.menu .ui.basic.bottom.attached.popup {
  top: 103px;
  display: block;
  visibility: hidden;
}

.borderless.menu .ui.basic.bottom.attached.popup.visible {
  visibility: visible;
}
</style>