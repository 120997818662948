import request from './../request';

const initialState = () => {
    return {
        flds: [],
        product: {},
        category: {},
        addOns: [],
        related: [],
        similar: [],
        recent: [],
        featuredProducts: []
    };
};

const getters = {};

const actions = {
    async getProduct({commit}, id) {
        const product = await request.get('/api/product/' + id);
        Object.keys(product.data).forEach(type => {
            commit('SET_TYPE', {
                type,
                data: product.data[type]
            });
        });
        return product.data;
    },
    getRecent({commit}) {
        return request.get('api/products/recent').then(res => {
            commit('SET_TYPE', {
                type: 'recent',
                data: res.data.data
            });
        }).catch(() => {
        });
    },
    getFeaturedProducts({commit}) {
        return request.get('/api/products/featured').then(res => {
            commit('SET_FEATURED_PRODUCTS', res.data.data);
            return res;
        }).catch(() => {
        });
    }
};

const mutations = {
    SET_TYPE(state, payload) {
        state[payload.type] = payload.data;
    },
    SET_FEATURED_PRODUCTS(state, payload) {
        state.featuredProducts = payload;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};