<template>
  <footer v-if="!mobile" class="ui blue inverted vertical footer segment">
    <div class="ui basic inverted segment container">
      <div class="ui grid">
        <div class="five wide column">
          <img src="../../images/logo.svg" class="ui tiny image" alt="E-Lux">
          <br>{{ __('Copyright ©') }}{{ year }}<br>{{ __('E-Lux Kodutehnika OÜ') }}<br>
          {{ __('Kõik õigused kaitstud') }}
        </div>
        <div class="six wide tablet five wide mobile column">
          <router-link class="ui inverted header" to="/kontakt">{{ __('Kontakt') }}</router-link>
          <div v-html="footer.content"></div>
        </div>
        <div class="five wide column">
          <div class="eight wide computer six wide tablet five wide mobile column">
            <div class="ui inverted header">{{ __('Kasulikku') }}</div>
            <div class="ui inverted link list">
              <router-link class="item" to="/kontakt">{{ __('Kontakt') }}</router-link>
              <router-link class="item" to="/uldtingimused">{{ __('Üldtingimused') }}</router-link>
              <router-link class="item" to="/privaatsuspoliitika">
                {{ __('Privaatsuspoliitika') }}
              </router-link>
              <router-link class="item" to="/hooldus">{{ __('Hooldus') }}</router-link>
              <router-link class="item" to="/garantii">{{ __('Garantii') }}</router-link>
              <router-link class="item" to="/tellimine">{{ __('Tellimine') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {__} from '../utils';
import {mapActions, mapState} from 'vuex';

export default {
  name: "AppFooter",
  computed: {
    ...mapState('Page', ['footer']),
    ...mapState('Loader', ['mobile'])
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    __,
    ...mapActions('Page', ['getFooter'])
  },
  created() {
    this.getFooter();
  }
}
</script>

<style>
footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  height: 20rem;
}
</style>