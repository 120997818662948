import request from './../request';

const initialState = () => {
    return {
        invoice: {},
        invoices: [],
        meta: {}
    };
};

const getters = {};

const actions = {
    getInvoices({commit}, params) {
        return request.get('/api/invoices', {params}).then(res => {
            commit('SET_INVOICES', res.data.data);
            commit('SET_META', res.data.meta);
            return res;
        }).catch(() => {
        });
    },
    async getInvoice({commit}, id) {
        const res = await request.get('/api/invoices/' + id);
        commit('SET_INVOICE', res.data.data);
        return res.data.data;
    }
};

const mutations = {
    SET_INVOICES(state, payload) {
        state.invoices = payload;
    },
    SET_META(state, payload) {
        state.meta = payload;
    },
    SET_INVOICE(state, payload) {
        state.invoice = payload;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};