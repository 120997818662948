<template>
  <div v-if="Object.keys(errs).length > 0 || error || warning">
    <div v-if="Object.keys(errs).length > 0 || error" class="ui vjs error message">
      <i class="close icon" @click="clear"></i>
      <div v-if="Object.keys(errs).length > 0 && error" class="header">
        {{ error }}
      </div>
      <ul v-if="Object.keys(errs).length > 0" class="list">
        <li v-for="err in errs" :key="err">{{ err[0] }}</li>
      </ul>
      <p v-if="error && Object.keys(errs).length === 0">
        {{ error }}
      </p>
    </div>
    <div v-else-if="warning" class="ui vjs warning message">
      <i class="close icon" @click="clear"></i>
      {{ warning }}
    </div>
    <div class="ui hidden clearing divider"></div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  computed: mapState('Messages', ['error', 'errs', 'warning']),
  methods: {
    clear() {
      this.clearMessages({manual: true});
    },
    ...mapMutations('Messages', {clearMessages: 'CLEAR_MESSAGES'})
  }
}
</script>
